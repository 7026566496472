<template>
	<div class="exception-body  error">
		<div class="exception-panel">
			<div class="exception-code">
				<img src="assets/layout/images/exception/500.svg" alt="serenity-react"/>
			</div>

			<div class="exception-detail">

				<div class="exception-icon">
					<i class="pi pi-times"></i>
				</div>
				<h1>ERROR OCCURED</h1>
				<p>Please contact the system administrator</p>

				<Button label="GO TO DASHBOARD" @click="goDashboard" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			goDashboard(){
				window.location = "/"
			}
		}
	}
</script>

<style scoped>

</style>
